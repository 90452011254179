<template>
  <div>
    <div class="cards">
      <div class="card">
        <img class="bg" src="@/assets/sewage/icon-bg.png" alt="" />
        <div class="content">
          <img class="icon" src="@/assets/sewage/icon1.png" alt="" />
          <div>
            <div class="label">本周降雨</div>
            <div class="data">
              <span class="value">134</span>
              <span>m³/h</span>
            </div>
          </div>
          <div>
            <div class="label">同比</div>
            <div class="ratio-up">
              <a-icon type="caret-up" style="font-size: 10px" />
              <span class="value">12</span>
              <span>%</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <img class="bg" src="@/assets/sewage/icon-bg.png" alt="" />
        <div class="content">
          <img class="icon" src="@/assets/sewage/icon2.png" alt="" />
          <div>
            <div class="label">本月降雨</div>
            <div class="data">
              <span class="value">511</span>
              <span>mm</span>
            </div>
          </div>
          <div>
            <div class="label">同比</div>
            <div class="ratio-down">
              <a-icon type="caret-down" style="font-size: 10px" />
              <span class="value">8</span>
              <span>%</span>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <img class="bg" src="@/assets/sewage/icon-bg.png" alt="" />

        <div class="content">
          <img class="icon" src="@/assets/sewage/icon3.png" alt="" />
          <div>
            <div class="label">24小时雨量预测</div>
            <div class="data">
              <span class="value">23</span>
              <span>mm</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex-aligh button-box">
      <Button>今年</Button>
      <Button>本周</Button>
      <a-range-picker
        :placeholder="['起始日期', '截止日期']"
        format="YYYY-MM-DD"
      >
        <span slot="suffixIcon">
          <a-icon type="calendar" />
        </span>
      </a-range-picker>

      <a-select placeholder="日报" style="width: 10vw">
        <a-select-option
          v-for="item in typeList"
          :key="item.value"
          :value="item.value"
          >{{ item.name }}</a-select-option
        >
      </a-select>
      <Button type="primary">本月</Button>
      <Button type="primary">查询</Button>
      <Button>重置</Button>
    </div>
    <div class="content-box flex">
      <Liquid />
      <Production />
    </div>
  </div>
</template>
<script>
import Liquid from "./Liquid";
import Production from "./Production";

export default {
  components: {
    Liquid,
    Production,
  },
  data() {
    return {
      typeList: [
        {
          name: "1",
          value: "1",
        },
        {
          name: "12",
          value: "12",
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
.cards {
  display: flex;
  align-items: center;
  gap: 2vw;
  margin-bottom: 2vh;
  padding: 1vh 3vw;
  .card {
    flex: 1;
    position: relative;
    .bg {
      width: 100%;
    }
    .content {
      position: absolute;
      top: 0;
      left: 10%;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      gap: 1.5vw;

      .icon {
        height: 6vh;
        margin-right: 2em;
      }
      .label {
        color: #c8eaff;
        font-weight: 500;
      }
      .data {
        font-family: YouSheBiaoTiHei;
        color: #00ffff;
        background: linear-gradient(180deg, #2cf39d 0%, #14eeef 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        .value {
          font-size: 2em;
        }
      }
      .ratio-up {
        font-family: URWDIN-Bold, URWDIN;
        font-weight: bold;
        background: linear-gradient(180deg, #22f138 0%, #cef32c 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        .value {
          font-size: 2em;
        }
      }

      .ratio-down {
        background: linear-gradient(180deg, #e65839 0%, #f3b72c 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        .value {
          font-size: 2em;
        }
      }
    }
  }
}

.button-box {
  gap: 1vw;
  margin-bottom: 5vh;
}
.content-box {
  gap: 2vw;
}
</style>
